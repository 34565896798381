<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="名称">
            <a-input v-model="form['customer_name']" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :customRow="
        (record, index) => {
          return {
            style: {
              background: record.is_valid === 1 ? '#ffe8e9' : ''
            }
          }
        }
      "
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="delRecord('row', record)">删除</a>
      </div>
      <div slot="bankInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <span style="color: #ffb34f;">{{ item.bank_name }}</span> : <span>{{ item.account }}</span>
        </div>
      </div>
      <div slot="allowInvoiceSlot" slot-scope="text">
        <a-tag :color="text === 'N'?'red':'green'">{{text === 'N'?'否':'是'}}</a-tag>
      </div>
    </a-table>
    <a-modal :width="650" v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="formModel"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="名称" prop="customer_name">
          <a-input v-model.trim="formModel.customer_name" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model.trim="formModel.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import {
  saveBlackList,
  deleteBlackList,
  queryCustomerInfoBlackLlist
} from '@/api/common'
export default {
  data() {
    return {
      labelCol: { span: 5 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      form: {
        company_code: '',
        type: null
      },
      formModel: {
        customer_name: '',
        remark: ''
      },
      rules: {
        customer_name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '中文名称',
          dataIndex: 'customer_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增客户黑名单信息',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      }
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    getInfo(param) {
      queryCustomerInfoBlackLlist({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    newRecord() {
      this.modelTitle = '新增客户供应商信息'
      this.visible = true
      this.formModel = {
        customer_name: '',
        remark: ''
      }
    },
    delRecord(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteBlackList({ blacklist_id: record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    handleSave() {
      this.$refs.formModel.validate(valid => {
        if (valid) {
          this.saveInfo()
        } else {
          return false
        }
      })
    },
    saveInfo() {
      saveBlackList(this.formModel).then(res => {
        if (res) {
          this.$message.success('保存成功!')
          const param = Object.assign({}, this.form)
          param.page = this.pagination.current
          param.pageSize = this.pagination.pageSize
          this.getInfo(param)
          this.visible = false
        } else {
          this.$message.error('保存失败!')
        }
      })
    },
    handleCancel(e) {
      this.$refs.formModel.resetFields()
      this.visible = false
    },
    addBank() {
      this.formModel.bank_account.push({
        bank_name: '',
        account: ''
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
