var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"card",attrs:{"bordered":false}},[_c('a-form-model',{attrs:{"model":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-row',[_c('a-col',_vm._b({},'a-col',_vm.GLOBAL.queryColSpan,false),[_c('a-form-model-item',{attrs:{"label":"名称"}},[_c('a-input',{model:{value:(_vm.form['customer_name']),callback:function ($$v) {_vm.$set(_vm.form, 'customer_name', $$v)},expression:"form['customer_name']"}})],1)],1)],1),_c('a-row',{staticStyle:{"margin":"15px 0"}},[_c('a-button',{staticClass:"ant-btn-query margin-right10",on:{"click":_vm.searchInfo}},[_vm._v("查询")]),_c('a-button',{staticClass:"margin-right10",attrs:{"type":"primary"},on:{"click":_vm.newRecord}},[_vm._v("新增")])],1)],1),_c('a-table',{attrs:{"rowKey":"id","size":"middle","scroll":{ x: true },"columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination,"customRow":(record, index) => {
        return {
          style: {
            background: record.is_valid === 1 ? '#ffe8e9' : ''
          }
        }
      }},on:{"change":_vm.changeTable},scopedSlots:_vm._u([{key:"serial",fn:function(text, record, index){return _c('div',{},[_vm._v(" "+_vm._s(index + 1)+" ")])}},{key:"action",fn:function(text, record){return _c('div',{},[_c('a',{on:{"click":function($event){return _vm.delRecord('row', record)}}},[_vm._v("删除")])])}},{key:"bankInfo",fn:function(text){return _c('div',{},_vm._l((text),function(item,index){return _c('div',{key:index},[_c('span',{staticStyle:{"color":"#ffb34f"}},[_vm._v(_vm._s(item.bank_name))]),_vm._v(" : "),_c('span',[_vm._v(_vm._s(item.account))])])}),0)}},{key:"allowInvoiceSlot",fn:function(text){return _c('div',{},[_c('a-tag',{attrs:{"color":text === 'N'?'red':'green'}},[_vm._v(_vm._s(text === 'N'?'否':'是'))])],1)}}])}),_c('a-modal',{attrs:{"width":650,"title":_vm.modelTitle},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"formModel",attrs:{"model":_vm.formModel,"rules":_vm.rules,"label-col":_vm.labelModel,"wrapper-col":_vm.wrapperModel}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"customer_name"}},[_c('a-input',{model:{value:(_vm.formModel.customer_name),callback:function ($$v) {_vm.$set(_vm.formModel, "customer_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formModel.customer_name"}})],1),_c('a-form-model-item',{attrs:{"label":"备注","prop":"remark"}},[_c('a-input',{model:{value:(_vm.formModel.remark),callback:function ($$v) {_vm.$set(_vm.formModel, "remark", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formModel.remark"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }